<template>
  <div class="popup_wrap" style="width:1500px;">
    <button class="layer_close" @click.prevent="layerClose()">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL020P190.001') }}</h1>
      <div class="tab cp-tab mt20">
        <ul>
          <li v-for="tab in tabs" :key="tab.id" :class="tabInfo.activeTab === tab.id ? 'on' : ''">
            <a class="" @click.stop="cpSelectTab(tab.id)">{{ tab.title }}</a>
          </li>
        </ul>
      </div>
      <div class="content_box beforetab cp-beforetab cp-parentnode">
        <div id="realgrid1" class="cp-realgrid" :class="{'cp-realgrid--hide': tabInfo.activeTab !== 'tab1'}"></div>
        <div id="realgrid2" class="cp-realgrid" :class="{'cp-realgrid--hide': tabInfo.activeTab !== 'tab2'}"></div>
        <div id="realgrid3" class="cp-realgrid" :class="{'cp-realgrid--hide': tabInfo.activeTab !== 'tab3'}"></div>
      </div>
      <div class="text_center mt10">
        <a class="button blue lg" href="#" @click.prevent="layerClose()">{{ $t('cp.COMMON.007') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import scenarioTargetSet from '@/api/rest/cp/scenarioTargetSet'
  import { GridView, LocalDataProvider } from 'realgrid'

  let [gridView1, gridView2, gridView3] = [GridView, GridView, GridView]
  let [provider1, provider2, provider3] = [LocalDataProvider, LocalDataProvider, LocalDataProvider]

  const fields1 = [
    { fieldName: 'opportunityNm', dataType: 'text' },
    { fieldName: 'bkgShipper', dataType: 'text' },
    { fieldName: 'accountNm', dataType: 'text' },
    { fieldName: 'closeDate', dataType: 'text' },
    { fieldName: 'porCtr', dataType: 'text' },
    { fieldName: 'porPlc', dataType: 'text' },
    { fieldName: 'dlyCtr', dataType: 'text' },
    { fieldName: 'dlyPlc', dataType: 'text' },
    { fieldName: 'teuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'revTeuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'cm1TeuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'transferGbn', dataType: 'text' },
    { fieldName: 'createdDate', dataType: 'text' }
  ]

  const fields2 = [
    { fieldName: 'promotionNm', dataType: 'text' },
    { fieldName: 'closeDate', dataType: 'text' },
    { fieldName: 'country', dataType: 'text' },
    { fieldName: 'porCtr', dataType: 'text' },
    { fieldName: 'porPlc', dataType: 'text' },
    { fieldName: 'dlyCtr', dataType: 'text' },
    { fieldName: 'dlyPlc', dataType: 'text' },
    { fieldName: 'promotionOwner', dataType: 'text' },
    { fieldName: 'teuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'revTeuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'cm1TeuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'transferGbn', dataType: 'text' },
    { fieldName: 'createdDate', dataType: 'text' }
  ]

  const fields3 = [
    { fieldName: 'promotionNm', dataType: 'text' },
    { fieldName: 'bkgShipper', dataType: 'text' },
    { fieldName: 'accountNm', dataType: 'text' },
    { fieldName: 'salUnoBs', dataType: 'text' },
    { fieldName: 'closeDate', dataType: 'text' },
    { fieldName: 'porCtr', dataType: 'text' },
    { fieldName: 'porPlc', dataType: 'text' },
    { fieldName: 'dlyCtr', dataType: 'text' },
    { fieldName: 'dlyPlc', dataType: 'text' },
    { fieldName: 'teuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'revTeuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'cm1TeuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'transferGbn', dataType: 'text' },
    { fieldName: 'createdDate', dataType: 'text' }
  ]

  const columns1 = [
    { name: 'opportunityNm', fieldName: 'opportunityNm', type: 'text', header: { text: app.$t('cp.CTRL020P190.002') }, width: 150, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'bkgShipper', fieldName: 'bkgShipper', type: 'text', header: { text: app.$t('cp.CTRL020P190.003') }, width: 110, editable: false, renderer: { showTooltip: true } },
    { name: 'accountNm', fieldName: 'accountNm', header: { text: app.$t('cp.CTRL020P190.004') }, width: 130, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'closeDate', fieldName: 'closeDate', header: { text: app.$t('cp.CTRL020P190.005') }, width: 90, editable: false },
    { name: 'porCtr', fieldName: 'porCtr', type: 'text', header: { text: app.$t('cp.CTRL020P190.006') }, width: 70, editable: false },
    { name: 'porPlc', fieldName: 'porPlc', type: 'text', header: { text: app.$t('cp.CTRL020P190.007') }, width: 70, editable: false },
    { name: 'dlyCtr', fieldName: 'dlyCtr', type: 'text', header: { text: app.$t('cp.CTRL020P190.008') }, width: 70, editable: false },
    { name: 'dlyPlc', fieldName: 'dlyPlc', type: 'text', header: { text: app.$t('cp.CTRL020P190.009') }, width: 70, editable: false },
    { name: 'teuTgt', fieldName: 'teuTgt', header: { text: app.$t('cp.CTRL020P190.010') }, width: 100, numberFormat: '0', editable: false },
    { name: 'revTeuTgt', fieldName: 'revTeuTgt', header: { text: app.$t('cp.CTRL020P190.011') }, width: 100, numberFormat: '0', editable: false },
    { name: 'cm1TeuTgt', fieldName: 'cm1TeuTgt', header: { text: app.$t('cp.CTRL020P190.012') }, width: 100, numberFormat: '0', editable: false },
    { name: 'transferGbn', fieldName: 'transferGbn', header: { text: app.$t('cp.CTRL020P190.013') }, width: 70, editable: false },
    { name: 'createdDate', fieldName: 'createdDate', header: { text: app.$t('cp.CTRL020P190.014') }, width: 130, editable: false }
  ]

  const columns2 = [
    { name: 'promotionNm', fieldName: 'promotionNm', type: 'text', header: { text: app.$t('cp.CTRL020P190.016') }, width: 150, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'closeDate', fieldName: 'closeDate', header: { text: app.$t('cp.CTRL020P190.005') }, width: 90, editable: false },
    // eslint-disable-next-line
    { name: 'country', fieldName: 'country', type: 'text', header: { text: app.$t('cp.CTRL020P190.017') }, width: 70, editable: false, renderer: { type: 'html', template: '${value:porCtr}' } },
    { name: 'porCtr', fieldName: 'porCtr', type: 'text', header: { text: app.$t('cp.CTRL020P190.006') }, width: 70, editable: false },
    { name: 'porPlc', fieldName: 'porPlc', type: 'text', header: { text: app.$t('cp.CTRL020P190.007') }, width: 70, editable: false },
    { name: 'dlyCtr', fieldName: 'dlyCtr', type: 'text', header: { text: app.$t('cp.CTRL020P190.008') }, width: 70, editable: false },
    { name: 'dlyPlc', fieldName: 'dlyPlc', type: 'text', header: { text: app.$t('cp.CTRL020P190.009') }, width: 70, editable: false },
    { name: 'promotionOwner', fieldName: 'promotionOwner', header: { text: app.$t('cp.CTRL020P190.018') }, width: 130, editable: false, renderer: { showTooltip: true } },
    { name: 'teuTgt', fieldName: 'teuTgt', header: { text: app.$t('cp.CTRL020P190.010') }, width: 100, numberFormat: '0', editable: false },
    { name: 'revTeuTgt', fieldName: 'revTeuTgt', header: { text: app.$t('cp.CTRL020P190.011') }, width: 100, numberFormat: '0', editable: false },
    { name: 'cm1TeuTgt', fieldName: 'cm1TeuTgt', header: { text: app.$t('cp.CTRL020P190.012') }, width: 100, numberFormat: '0', editable: false },
    { name: 'transferGbn', fieldName: 'transferGbn', header: { text: app.$t('cp.CTRL020P190.013') }, width: 70, editable: false },
    { name: 'createdDate', fieldName: 'createdDate', header: { text: app.$t('cp.CTRL020P190.014') }, width: 130, editable: false }
  ]

  const columns3 = [
    { name: 'promotionNm', fieldName: 'promotionNm', type: 'text', header: { text: app.$t('cp.CTRL020P190.016') }, width: 150, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'bkgShipper', fieldName: 'bkgShipper', header: { text: app.$t('cp.CTRL020P190.019') }, width: 130, editable: false, renderer: { showTooltip: true } },
    { name: 'accountNm', fieldName: 'accountNm', header: { text: app.$t('cp.CTRL020P190.020') }, width: 130, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'salUnoBs', fieldName: 'salUnoBs', header: { text: app.$t('cp.CTRL020P190.021') }, width: 130, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'closeDate', fieldName: 'closeDate', header: { text: app.$t('cp.CTRL020P190.005') }, width: 90, editable: false },
    { name: 'porCtr', fieldName: 'porCtr', type: 'text', header: { text: app.$t('cp.CTRL020P190.006') }, width: 70, editable: false },
    { name: 'porPlc', fieldName: 'porPlc', type: 'text', header: { text: app.$t('cp.CTRL020P190.007') }, width: 70, editable: false },
    { name: 'dlyCtr', fieldName: 'dlyCtr', type: 'text', header: { text: app.$t('cp.CTRL020P190.008') }, width: 70, editable: false },
    { name: 'dlyPlc', fieldName: 'dlyPlc', type: 'text', header: { text: app.$t('cp.CTRL020P190.009') }, width: 70, editable: false },
    { name: 'teuTgt', fieldName: 'teuTgt', header: { text: app.$t('cp.CTRL020P190.010') }, width: 100, numberFormat: '0', editable: false },
    { name: 'revTeuTgt', fieldName: 'revTeuTgt', header: { text: app.$t('cp.CTRL020P190.011') }, width: 100, numberFormat: '0', editable: false },
    { name: 'cm1TeuTgt', fieldName: 'cm1TeuTgt', header: { text: app.$t('cp.CTRL020P190.012') }, width: 100, numberFormat: '0', editable: false },
    { name: 'transferGbn', fieldName: 'transferGbn', header: { text: app.$t('cp.CTRL020P190.013') }, width: 70, editable: false },
    { name: 'createdDate', fieldName: 'createdDate', header: { text: app.$t('cp.CTRL020P190.014') }, width: 130, editable: false }
  ]

  const layout1 = [
    'opportunityNm',
    'bkgShipper',
    'accountNm',
    'closeDate',
    'porCtr',
    'porPlc',
    'dlyCtr',
    'dlyPlc',
    {
      name: 'opprTgt',
      direction: 'horizontal',
      items: [
        'teuTgt',
        'revTeuTgt',
        'cm1TeuTgt'
      ],
      header: {
        text: app.$t('cp.CTRL020P190.015')
      }
    },
    'transferGbn',
    'createdDate'
  ]

  const layout2 = [
    'promotionNm',
    'closeDate',
    'country',
    'porCtr',
    'porPlc',
    'dlyCtr',
    'dlyPlc',
    'promotionOwner',
    {
      name: 'promoTgt',
      direction: 'horizontal',
      items: [
        'teuTgt',
        'revTeuTgt',
        'cm1TeuTgt'
      ],
      header: {
        text: app.$t('cp.CTRL020P190.022')
      }
    },
    'transferGbn',
    'createdDate'
  ]

  const layout3 = [
    'promotionNm',
    'bkgShipper',
    'accountNm',
    'salUnoBs',
    'closeDate',
    'porCtr',
    'porPlc',
    'dlyCtr',
    'dlyPlc',
    {
      name: 'promoTgt',
      direction: 'horizontal',
      items: [
        'teuTgt',
        'revTeuTgt',
        'cm1TeuTgt'
      ],
      header: {
        text: app.$t('cp.CTRL020P190.023')
      }
    },
    'transferGbn',
    'createdDate'
  ]

  export default {
    name: 'SfLogPop',
    components: {
    },
    props: {
      parentInfo: {
        type: Object,
        default: function () {
          return {
          }
        }
      }
    },
    data: function () {
      return {
        tabs: [
          { id: 'tab1', title: this.$t('cp.CTRL020P190.024') },
          { id: 'tab2', title: this.$t('cp.CTRL020P190.025') },
          { id: 'tab3', title: this.$t('cp.CTRL020P190.026') }
        ],
        tabInfo: {
          activeTab: 'tab1'
        }
      }
    },
    mounted () {
      const $vm = this

      provider1 = new LocalDataProvider(true)
      gridView1 = new GridView('realgrid1')
      gridView1.setDataSource(provider1)
      gridView1.header.heights = [22, 23]
      gridView1.header.heights = [22, 23]
      gridView1.header.heights = [22, 23]
      gridView1.header.showTooltip = false
      gridView1.footers.visible = false
      gridView1.setDisplayOptions({
        rowHeight: 35,
        fitStyle: 'even',
        hscrollBar: true,
        selectionStyle: 'block',
        columnMovable: false
      })
      gridView1.setStateBar({
        visible: false
      })
      gridView1.setRowIndicator({
        visible: false
      })
      gridView1.setCheckBar({
        visible: false
      })
      gridView1.setSortingOptions({
        textCase: 'insensitive',
        keepFocusedRow: true,
        style: 'inclusive',
        showSortOrder: true
      })
      gridView1.setFilteringOptions({
        enabled: false
      })

      provider2 = new LocalDataProvider(true)
      gridView2 = new GridView('realgrid2')
      gridView2.setDataSource(provider2)
      gridView2.header.heights = [22, 23]
      gridView2.header.heights = [22, 23]
      gridView2.header.heights = [22, 23]
      gridView2.header.showTooltip = false
      gridView2.footers.visible = false
      gridView2.setDisplayOptions({
        rowHeight: 35,
        fitStyle: 'even',
        hscrollBar: true,
        selectionStyle: 'block',
        columnMovable: false
      })
      gridView2.setStateBar({
        visible: false
      })
      gridView2.setRowIndicator({
        visible: false
      })
      gridView2.setCheckBar({
        visible: false
      })
      gridView2.setSortingOptions({
        textCase: 'insensitive',
        keepFocusedRow: true,
        style: 'inclusive',
        showSortOrder: true
      })
      gridView2.setFilteringOptions({
        enabled: false
      })

      provider3 = new LocalDataProvider(true)
      gridView3 = new GridView('realgrid3')
      gridView3.setDataSource(provider3)
      gridView3.header.heights = [22, 23]
      gridView3.header.heights = [22, 23]
      gridView3.header.heights = [22, 23]
      gridView3.header.showTooltip = false
      gridView3.footers.visible = false
      gridView3.setDisplayOptions({
        rowHeight: 35,
        fitStyle: 'even',
        hscrollBar: true,
        selectionStyle: 'block',
        columnMovable: false
      })
      gridView3.setStateBar({
        visible: false
      })
      gridView3.setRowIndicator({
        visible: false
      })
      gridView3.setCheckBar({
        visible: false
      })
      gridView3.setSortingOptions({
        textCase: 'insensitive',
        keepFocusedRow: true,
        style: 'inclusive',
        showSortOrder: true
      })
      gridView3.setFilteringOptions({
        enabled: false
      })

      provider1.setFields(fields1)
      provider2.setFields(fields2)
      provider3.setFields(fields3)

      gridView1.setColumns(columns1)
      gridView2.setColumns(columns2)
      gridView3.setColumns(columns3)

      gridView1.setColumnLayout(layout1)
      gridView2.setColumnLayout(layout2)
      gridView3.setColumnLayout(layout3)

      $vm.setAllGridList()
    },
    methods: {
      cpSelectTab (id) {
        this.tabInfo.activeTab = id
      },
      async setAllGridList () {
        const scenarioCd = this.parentInfo.sfOpt.groupKeyCheck.scenarioCd
        const promise1 = await scenarioTargetSet.opportunityTransferLog(scenarioCd).then(response => response).catch(err => err.response)
        const promise2 = await scenarioTargetSet.promotionTransferLog(scenarioCd).then(response => response).catch(err => err.response)
        const promise3 = await scenarioTargetSet.promotionActivityTransferLog(scenarioCd).then(response => response).catch(err => err.response)

        await Promise.allSettled([promise1, promise2, promise3]).then(response => {
          const gridList = response.map(elem => elem.value.data)
          provider1.setRows(gridList[0])
          provider2.setRows(gridList[1])
          provider3.setRows(gridList[2])
        }).catch(err => {
          console.error(err)
        })
      },
      layerClose () {
        this.$emit('close')
        $('.popup_dim').fadeOut()
        $('body,html').removeAttr('style')
        return false
      }
    }
  }
</script>
<style>
  .cp-tab li a {
    cursor: pointer;
    user-select: none;
    padding: 0 20px !important;
  }

  .cp-tab li a:hover {
    color: #063CAA !important;
  }

  .cp-beforetab {
    margin-top: 0px !important;
  }

  .cp-realgrid {
    width: 100%;
    height: 483px;
  }

  .cp-realgrid--hide {
    display: none;
  }
</style>
