var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "1500px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.layerClose()
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("cp.CTRL020P190.001"))),
        ]),
        _c("div", { staticClass: "tab cp-tab mt20" }, [
          _c(
            "ul",
            _vm._l(_vm.tabs, function (tab) {
              return _c(
                "li",
                {
                  key: tab.id,
                  class: _vm.tabInfo.activeTab === tab.id ? "on" : "",
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.cpSelectTab(tab.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(tab.title))]
                  ),
                ]
              )
            }),
            0
          ),
        ]),
        _c(
          "div",
          { staticClass: "content_box beforetab cp-beforetab cp-parentnode" },
          [
            _c("div", {
              staticClass: "cp-realgrid",
              class: { "cp-realgrid--hide": _vm.tabInfo.activeTab !== "tab1" },
              attrs: { id: "realgrid1" },
            }),
            _c("div", {
              staticClass: "cp-realgrid",
              class: { "cp-realgrid--hide": _vm.tabInfo.activeTab !== "tab2" },
              attrs: { id: "realgrid2" },
            }),
            _c("div", {
              staticClass: "cp-realgrid",
              class: { "cp-realgrid--hide": _vm.tabInfo.activeTab !== "tab3" },
              attrs: { id: "realgrid3" },
            }),
          ]
        ),
        _c("div", { staticClass: "text_center mt10" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.layerClose()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cp.COMMON.007")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }